body {
    margin: 0;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none !important;
}

input,
select,
option {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
        "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./assets/fonts/Inter-Bold.woff2?v=3.18") format("woff2"),
        url("./assets/fonts/Inter-Bold.woff?v=3.18") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("./assets/fonts/Inter-Medium.woff2?v=3.18") format("woff2"),
        url("./assets/fonts/Inter-Medium.woff?v=3.18") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("./assets/fonts/Inter-SemiBold.woff2?v=3.18") format("woff2"),
        url("./assets/fonts/Inter-SemiBold.woff?v=3.18") format("woff");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./assets/fonts/Inter-Regular.woff2?v=3.18") format("woff2"),
        url("./assets/fonts/Inter-Regular.woff?v=3.18") format("woff");
}

.popoverOuter {
    padding: 0 32px;
}

@media screen and (min-width: 768px) {
    .popoverOuter {
        padding: 0;
    }
}
