/* PrismJS 1.25.0
https://prismjs.com/download.html#themes=prism-okaidia&languages=markup+css+clike+javascript+jsx&plugins=toolbar+copy-to-clipboard */
code[class*="language-"],
pre[class*="language-"] {
    color: #f8f8f2;
    background: 0 0;
    text-shadow: 0 1px rgba(0, 0, 0, 0.3);
    font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
    font-size: 0.9em;
    text-align: left;
    white-space: pre-wrap;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre[class*="language-"] {
    padding: 1em;
    margin: 0.5em 0 2em 0;
    overflow: auto;
    border-radius: 0.3em;
    width: 100%;
    max-width: 800px;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #272822;
}
:not(pre) > code[class*="language-"] {
    padding: 0.1em;
    border-radius: 0.3em;
    white-space: normal;
}
.token.cdata,
.token.comment,
.token.doctype,
.token.prolog {
    color: #8292a2;
}
.token.punctuation {
    color: #f8f8f2;
}
.token.namespace {
    opacity: 0.7;
}
.token.constant,
.token.deleted,
.token.property,
.token.symbol,
.token.tag {
    color: #f92672;
}
.token.boolean,
.token.number {
    color: #ae81ff;
}
.token.attr-name,
.token.builtin,
.token.char,
.token.inserted,
.token.selector,
.token.string {
    color: #a6e22e;
}
.language-css .token.string,
.style .token.string,
.token.entity,
.token.operator,
.token.url,
.token.variable {
    color: #f8f8f2;
}
.token.atrule,
.token.attr-value,
.token.class-name,
.token.function {
    color: #e6db74;
}
.token.keyword {
    color: #66d9ef;
}
.token.important,
.token.regex {
    color: #fd971f;
}
.token.bold,
.token.important {
    font-weight: 700;
}
.token.italic {
    font-style: italic;
}
.token.entity {
    cursor: help;
}
div.code-toolbar {
    position: relative;
}
div.code-toolbar > .toolbar {
    position: absolute;
    z-index: 10;
    top: 0.3em;
    right: 0.2em;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
}
div.code-toolbar:hover > .toolbar {
    opacity: 1;
}
div.code-toolbar:focus-within > .toolbar {
    opacity: 1;
}
div.code-toolbar > .toolbar > .toolbar-item {
    display: inline-block;
}
div.code-toolbar > .toolbar > .toolbar-item > a {
    cursor: pointer;
}
div.code-toolbar > .toolbar > .toolbar-item > button {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
div.code-toolbar > .toolbar > .toolbar-item > a,
div.code-toolbar > .toolbar > .toolbar-item > button,
div.code-toolbar > .toolbar > .toolbar-item > span {
    color: #bbb;
    font-size: 0.8em;
    padding: 0 0.5em;
    background: #f5f2f0;
    background: rgba(224, 224, 224, 0.2);
    box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
    border-radius: 0.5em;
}
div.code-toolbar > .toolbar > .toolbar-item > a:focus,
div.code-toolbar > .toolbar > .toolbar-item > a:hover,
div.code-toolbar > .toolbar > .toolbar-item > button:focus,
div.code-toolbar > .toolbar > .toolbar-item > button:hover,
div.code-toolbar > .toolbar > .toolbar-item > span:focus,
div.code-toolbar > .toolbar > .toolbar-item > span:hover {
    color: inherit;
    text-decoration: none;
}
